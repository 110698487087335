import React from 'react';
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import SmartLink from '../components/atoms/SmartLink/SmartLink';
import Layout from '../components/organisms/Layout/Layout'

const NotFoundPage = () => (
  <Layout>
    <PageWidth>
      <h1>Ooooooops</h1>
      <p>
        This page does not exist (anymore). Try the <SmartLink to="/" punctuation=".">
          homepage
        </SmartLink>
      </p>
    </PageWidth>
  </Layout>
);

export default NotFoundPage;
